/*
// FINDING-6391 due to the way the search/browse price facet "TO" text
// is positioned, it overlaps the actual input boxes. the input boxes
// themselves cannot be set to $nav-utils-wrap-z-index otherwise the
// they will go over the scrolled fix nav. The text and input
// boxes cannot be on the same z-index otherwise the input boxes
// cannot receive focus in the middle of the input box
// if z-index is -1, it will hide the "TO" text beneath the facet
*/
.wrapper {
  position: relative;
  width: 100vw;
  left: calc((100vw - 100%) / -2);
  margin-top: 27px 0 18px;
}

.list {
  margin: 0 9px;
  align-items: stretch;
}

.item {
  align-items: inherit;
}

.tile {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #ddd;
  padding: 9px;
  text-decoration: none;
}

.image {
  max-height: 130px;
  max-width: 100%;
}

.title {
  font-family: "Cardinal Classic Short", "Georgia", "serif";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  margin: auto;
  padding-top: 9px;
}