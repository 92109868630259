.wrapper {
  width: 100vw;
  margin-top: 27px 0 18px;
  position: relative;
  left: calc(-50vw + 50%);
}

.list {
  align-items: stretch;
  margin: 0 9px;
}

.item {
  align-items: inherit;
}

.tile {
  border: 1px solid #ddd;
  flex-direction: column;
  align-items: center;
  padding: 9px;
  text-decoration: none;
  display: flex;
}

.image {
  max-width: 100%;
  max-height: 130px;
}

.title {
  text-align: center;
  margin: auto;
  padding-top: 9px;
  font-family: Cardinal Classic Short, Georgia, "serif";
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
}
